import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { BuildingClass } from '../building/model/building-class.model';

export const USER_BASE_URI = `${environment.apiUrl}/context/populate`;

export interface ParamsContextObjectDto {
  entityType: string;
  contextCode: string;
}

@Injectable({
  providedIn: 'root',
})

export class CockpitService{

  constructor(private http: HttpClient) {}

  sendObjectContext(requestObject: ParamsContextObjectDto) {
    const url = `${USER_BASE_URI}`;

    return this.http.post(url, requestObject).pipe(
      catchError(error => throwError('An error occurred during the API call'))
    );
  }
}
