import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../model/user.model';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import IdentityManagerRegisterTokenProperties = __esri.IdentityManagerRegisterTokenProperties;
import esriId from '@arcgis/core/identity/IdentityManager';
import ServerInfo from '@arcgis/core/identity/ServerInfo';

export const USER_BASE_URI = `${environment.apiUrl}/user`;
export const USER_BASE_URI_ROLE = `${environment.apiUrl}/role`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly http: HttpClient) {}

  findUser(): Observable<User> {
    return this.http.get<User>(`${USER_BASE_URI}/`);
  }

  updateLanguage(language: string): Observable<void> {
    return this.http.put<void>(`${USER_BASE_URI}/language`, { language });
  }

  registerGisToken(user: User, portalUrl: string): Observable<User> {
    if (!user.gisToken) {
      console.error('No gis token, aborting');
    }

    const properties = {
      //server: portalUrl + environment.arcGisServicesBasePath,
      server : portalUrl + '/portal/sharing',
      token: user.gisToken,
    } as IdentityManagerRegisterTokenProperties;
    IdentityManager.registerToken(properties);

    const serverInfo = new ServerInfo();
    serverInfo.server = portalUrl;
    serverInfo.hasPortal = true;
    esriId.registerServers([serverInfo]);


    // const propsAgain = {
    //   server: portalUrl + '/portal/sharing/servers',
    //   token: user.gisToken,
    // } as IdentityManagerRegisterTokenProperties;
    // IdentityManager.registerToken(propsAgain);
    return of(user);
  }

  updateActiveRole(newRole: string): Observable<User>{
    return this.http.put<User>(`${USER_BASE_URI_ROLE}/update-active-role`, newRole);
  }

  checkUserAccessToBuilding(idBuilding: number, idUser: number): Observable<boolean> {
    return this.http.post<boolean>(`${USER_BASE_URI}/check-access`, {
      idObject: Number(idBuilding),
      idUser: Number(idUser),
      contextCode: 'layer_context_1',
      objectType: 'BUILDING'
    });
  }
}
